import Fuse from 'fuse.js';

function fuzzyMatchingText(textArray, userInput) {
  if (!userInput || !textArray || textArray.length === 0) {
    return [];
  }

  // Ensure userInput is an array
  const inputs = Array.isArray(userInput) ? userInput : [userInput];

  // Setup Fuse.js options for fuzzy matching
  const fuse = new Fuse(textArray, {
    includeScore: true,
    threshold: 0.3, // Adjust threshold for stricter or looser matches
  });

  // Perform matching for each input in the array
  const results = inputs.map((input) => {
    // First, check for an exact match
    if (textArray.includes(input)) {
      return input;
    } else {
      // Else, perform fuzzy search
      const result = fuse.search(input);
      return result.length ? result[0].item : "";
    }
  });

  return results; // Return an array of results for each input
}

export default fuzzyMatchingText;
