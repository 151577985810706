// FinanceProfessional.js
import React, { useState } from 'react';
import {Card,Paper} from '@mui/material';
import { EditModal } from './EditProfileSection';
import { AboutSection, CertificationSection, FAQSection, FeesSection, ProfileBanner, QualificationSection } from './FinanceProfessionalProfile';


const FinanceProfessional = ({ profileFinancial = financeProfessionals[0] }) => {
  const [edit, setEdit] = useState(false);
  const [currentSection, setCurrentSection] = useState('');
  const [profile, setprofile] = useState(profileFinancial);

  const handleEdit = (section) => {
    setCurrentSection(section);
    setEdit(true);
  };

  // Close dialog
  const handleClose = () => {
    setEdit(false);
  };

  // Centralized input change handling logic
  const handleChange = (e, parentKey = null, key = null) => {
    const { name, value } = e.target;
  
    setprofile((prevProfile) => {
      if (parentKey && key) {
        // Handling nested arrays (e.g., servicesProvided.detailServices)
        return {
          ...prevProfile,
          [parentKey]: {
            ...prevProfile[parentKey],
            [key]: value.split(',').map((item) => item.trim()),
          },
        };
      } else if (parentKey) {
        // Handling nested objects (e.g., region.city)
        return {
          ...prevProfile,
          [parentKey]: {
            ...prevProfile[parentKey],
            [name]: value,
          },
        };
      } else {
        // Handling top-level object properties (e.g., name, role)
        return { ...prevProfile, [name]: value };
      }
    });
  };
  
  return (
    <>
<Paper style={{ padding: '1rem',display:"flex",gap:"1rem",flexWrap:"wrap"}}>
      <div style={{display:"flex",flexDirection:"column",gap:"1rem",flex:1}}>
         <ProfileBanner profile={profile} handleEdit={handleEdit}/>
         <AboutSection profile={profile} handleEdit={handleEdit}/>
         <FeesSection profile={profile} handleEdit={handleEdit}/>
         <QualificationSection profile={profile} handleEdit={handleEdit}/>
         <CertificationSection profile={profile} handleEdit={handleEdit}/>
         <FAQSection profile={profile} handleEdit={handleEdit}/>
      </div>
    
      <div style={{minWidth:"300px",flex:0,height:"auto"}}>
      <Card>

      </Card>
      </div>
      </Paper>

      {/* Unified Dialog Component */}
     <EditModal edit={edit} 
     handleChange={handleChange} 
     handleClose={handleClose} 
     currentSection={currentSection}
     profile={profile}
     />
   

</>
  );
};



export default FinanceProfessional;

// Sample Data
const financeProfessionals = [
  {
    id: '',
    name: '',
    profilePicture: '',
    region: {
      country: '',
      state: '',
      city: '',
    },
    contactDetails: {
      email: '',
      phone: '',
      website: '',
    },
    rating:4,
    role: '',
    organization: '',
    description: '',
    servicesProvided: {
      coreExpertise: [],
      detailServices: [],
    },
    feeStructure: {
      hourlyRate: '',
      projectFee: '',
      retainer: '',
    },
    otherDetails: {
      languages: [],
      availability: '',
    },
    faq: [],
    reviews:[],
  },
];
