import React, { useEffect, useRef } from 'react'
import ReactMarkdown from 'react-markdown';
import { Button, CircularProgress } from '@mui/material';
import useHandleUserEvents from './useHandleUserEvents';
import CompanyProfile from 'analytics/ticker/stocks/Overview/CompanyProfile';
import { PortfolioCurrentPositionAll } from 'myFinancials/Portfolio/PositionsAndTransactions/PortfolioCurrentPositions';
import PortfolioOverview from 'myFinancials/Portfolio/PortfolioOverview';
import rehypeRaw from 'rehype-raw';
import { parse, Allow } from "partial-json";
import TextWithBorders from 'components/Text/TextWithBorders';
import MyFinancialHealthCard from 'myFinancials/MyFinancialHealthCard';
import AssetAllocation from 'myFinancials/Portfolio/AssetAllocation';
import StocksInfoList from 'analytics/ticker/StocksInfoList';
import { Link } from 'react-router-dom';
import GoalsProjection from 'myFinancials/Goals/GoalsProjection';
import AssetProjections from 'myFinancials/Asset/AssetProjections';
import AmortizationTable from 'myFinancials/Liability/AmortizationTable';
import NetCashFlowProjection from 'myFinancials/Cashflow/NetCashFlowProjection';
import TickerCommonInfoList from 'analytics/ticker/TickerCommonInfoList';
import GoalIndivdiualDetails from 'myFinancials/Goals/GoalIndivdiualDetails';
import MyFinancialsOverview from 'myFinancials/MyFinancialsOverview';
import AssetDetails from 'myFinancials/Asset/AssetDetails';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import NearMeIcon from '@mui/icons-material/NearMe';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InfoBox from 'components/Layout/InfoBox';
import SourceIcon from '@mui/icons-material/Source';
import ArticleIcon from '@mui/icons-material/Article';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LayersIcon from '@mui/icons-material/Layers';
import LiabilityDetails from 'myFinancials/Liability/LiabilityDetails';
import PortfolioVsBenchmark from 'myFinancials/Portfolio/PortfolioVsBenchmark';
import RiskTolerance from 'myFinancials/RiskManagement/RiskTolerance';
import Income from 'myFinancials/Cashflow/Income';
import ExpenseDetails from 'myFinancials/Cashflow/ExpenseDetails';
import NetCashAllocation from 'myFinancials/Cashflow/NetCashAllocation';
import TickerInfoList from 'analytics/ticker/TickerInfoList';
import PortfolioFit from 'opportunities/PortfolioFit';
import MarketCallStocks from 'opportunities/MarketCallStocks';
import { takestockReports } from 'reportTakeStock/reportContent';
import { NetCashflowOverviewDetails } from 'myFinancials/Cashflow/NetCashflowOverview';
import GoalIndividualDetailNew from 'myFinancials/Goals/GoalIndividualDetailNew';
import FinancialAdvisorConnect from 'myFinancials/FinancialAdvisorConnect';
import TickerVsPeers from 'analytics/ticker/TickerVsPeers';
import CompanyEarningTranscripts from 'newsEvents/CompanyEarningTranscripts';
import { useAppInfo } from 'AppState';
import MarketNews from 'newsEvents/MarketNews';
import { IndSecInfoList } from 'analytics/industry/IndSecInfoList';
import TradeConditions from 'trade/TradeConditions';
import TickerBuySellCondition from 'trade/TickerBuySellCondition';




const componentMap = {
  "EVMultiple":<StocksInfoList info={"EV Multiple"}/>,
  "PriceMultiple":<StocksInfoList info={"Price Multiple"}/>,
  "ValuationDCF":<StocksInfoList info={"DCF"}/>,
  "StockVsIndustry":<StocksInfoList info={"Stats vs Industry"}/>,
  "PriceVsPeers":<TickerCommonInfoList info={"Price vs Peers"}/>,
  "Performance":<TickerCommonInfoList info={"Performance"}/>,
  "Volatility":<TickerCommonInfoList info={"Volatility"}/>,
  "Correlation":<TickerCommonInfoList info={"Correlation"}/>,
  "TickerVsEconomy":<TickerCommonInfoList info={"Price vs Economy"}/>,
  "TechnicalIndicators":<TickerCommonInfoList info={"Indicators"}/>,
  TickerVsBenchmark:<TickerCommonInfoList info={"Price vs Benchmark"}/>,
  "TickerVsPeers":<TickerVsPeers/>,
  TickerOverview: <CompanyProfile />,
  "FinancialHealth":<MyFinancialHealthCard/>,
  "FinancialOverview":<MyFinancialsOverview/>,
  "GoalsProjection":<GoalsProjection/>,
  "AssetProjection":<AssetProjections/>,
  "LiabilityProjection":<AmortizationTable/>,
  "CashflowProjection":<NetCashFlowProjection/>,
  "FinancialsStock":<StocksInfoList info={"Statements"}/>,
  "FinancialsStockPeers":<StocksInfoList info={"Financials vs Peers"}/>,
  "chart_GoalSuccess":<GoalIndivdiualDetails edit={false}/>,
  "AssetDetails":<AssetDetails/>,
  "LiabilityDetails":<LiabilityDetails/>,
  "PortfolioValue": <PortfolioOverview/>,
  "PortfolioCurrentPosition":<PortfolioCurrentPositionAll/>,
  "PortfolioAssetAllocation":<AssetAllocation/>,
  "PortfolioVsBenchmark":<PortfolioVsBenchmark/>,
  "RiskTolerance":<RiskTolerance/>,
  "NetCashflow":<NetCashflowOverviewDetails/>,
  "Income":<Income/>,
  "Expenses":<ExpenseDetails/>,
  "NetCashflowAllocate":<NetCashAllocation/>,
  "TickerProfile":<TickerInfoList infoName={"Profile"}/>,
  "PortfolioFit":<PortfolioFit/>,
  "InvestmentOpportunity":<MarketCallStocks strategy={"Long Term Value"}/>,
  "ReportMarket":takestockReports?.Market,
  "ReportEconomy":takestockReports?.Economy,
  "ReportPortfolio":takestockReports?.Portfolio,
  "ReportPersonalFinance":takestockReports?.PersonalFinancials,
  "NewGoal":<></>,  
  "EarningTranscript":<CompanyEarningTranscripts/>,
  "Industry":<IndSecInfoList/>,
  "TickerTradeCondition":<TradeConditions tickerHeader={true}/>
};

function ChatComponents({react_id,component}){
  const {stateAssistant} = useAppInfo() 
  const {componentProps} = stateAssistant

  const match_componentProperties = componentProps ? componentProps.filter(obj=>obj.id===react_id) : []
  const props = match_componentProperties.length > 0 ? match_componentProperties[0] : undefined

  // console.log("Props",react_id,props,componentProps)
  const componentList = {
    "EVMultiple":<StocksInfoList info={"EV Multiple"}/>,
    "PriceMultiple":<StocksInfoList info={"Price Multiple"}/>,
    "ValuationDCF":<StocksInfoList info={"DCF"}/>,
    "StockVsIndustry":<StocksInfoList info={"Stats vs Industry"}/>,
    "Scatter":<StocksInfoList info={"Scatter"}/>,
    "StatsVsPeers":<StocksInfoList info={"Stats vs Peers"}/>,
    "PriceVsPeers":<TickerCommonInfoList info={"Price vs Peers"}/>,
    "Performance":<TickerCommonInfoList info={"Performance"}/>,
    "Volatility":<TickerCommonInfoList info={"Volatility"}/>,
    "Correlation":<TickerCommonInfoList info={"Correlation"}/>,
    "TickerVsEconomy":<TickerCommonInfoList info={"Price vs Economy"}/>,
    "TechnicalIndicators":<TickerCommonInfoList info={"Indicators"}/>,
    "FinancialsVsPeers":<StocksInfoList info={"Financials vs Peers"}/>,
    "FinancialsStockPeers":<StocksInfoList info={"Financials vs Peers"}/>,
    TickerVsBenchmark:<TickerCommonInfoList info={"Price vs Benchmark"}/>,
    "TickerVsPeers":<TickerVsPeers/>,
    TickerOverview: <CompanyProfile />,
    "FinancialHealth":<MyFinancialHealthCard/>,
    "FinancialOverview":<MyFinancialsOverview/>,
    "GoalsProjection":<GoalsProjection/>,
    "AssetProjection":<AssetProjections/>,
    "LiabilityProjection":<AmortizationTable/>,
    "CashflowProjection":<NetCashFlowProjection/>,
    "FinancialsStock":<StocksInfoList info={"Statements"}/>,
    "chart_GoalSuccess":<GoalIndivdiualDetails edit={false}/>,
    "AssetDetails":<AssetDetails/>,
    "LiabilityDetails":<LiabilityDetails/>,
    "PortfolioValue": <PortfolioOverview/>,
    "PortfolioCurrentPosition":<PortfolioCurrentPositionAll/>,
    "PortfolioAssetAllocation":<AssetAllocation/>,
    "PortfolioVsBenchmark":<PortfolioVsBenchmark/>,
    "RiskTolerance":<RiskTolerance/>,
    "NetCashflow":<NetCashflowOverviewDetails/>,
    "Income":<Income/>,
    "Expenses":<ExpenseDetails/>,
    "NetCashflowAllocate":<NetCashAllocation/>,
    "TickerProfile":<TickerInfoList infoName={"Profile"}/>,
    "PortfolioFit":<PortfolioFit/>,
    "InvestmentOpportunity":<MarketCallStocks strategy={"Long Term Value"}/>,
    "ReportMarket":takestockReports?.Market,
    "ReportEconomy":takestockReports?.Economy,
    "ReportPortfolio":takestockReports?.Portfolio,
    "ReportPersonalFinance":takestockReports?.PersonalFinancials,
    "NewGoal":<GoalIndividualDetailNew props={props}/>,  
    "EarningTranscript":<CompanyEarningTranscripts props={props}/>,
    "News":<MarketNews info={props?.newsType}/>,
  "Industry":<IndSecInfoList/>,
  "TickerBuySellCondition":<TickerBuySellCondition key={react_id} ticker={props?.ticker} tickerHeader={true}/>
  };

  return <>{componentList[component]}</>
}


export default function ContentRender({content}) {

    const {stateAssistant} = useAppInfo() 
    const {componentProps} = stateAssistant
    // console.log(newGoalChat)
    const { handleMessage, handleSend } = useHandleUserEvents();

    let parsedContent;

    try {
      // Parse the string into an object
      parsedContent = parse(content);
    } catch (error) {
      return <ReactMarkdown rehypePlugins={rehypeRaw}>{content}</ReactMarkdown>
      console.error('Error parsing content:', error);
      return <p>Invalid content format.</p>;  // Show an error message if parsing fails
    }

    // console.log(parsedContent)
    // Extract data from parsed object
    const key_insights = parsedContent?.key_insights
    const next_best_move = parsedContent?.next_best_move
    const sources = parsedContent?.sources
    const introduction = parsedContent?.introduction;
    const explanation = parsedContent?.explanation;
    const component = parsedContent?.component;
    const finalAnswer = parsedContent?.final_answer;
    const relatedQuestions = parsedContent?.related_questions;
    const learnMore = parsedContent?.learn_more

    // console.log(component)

    return (
      <div style={{display:"flex",gap:"0rem",flexDirection:"column",gap:"1rem"}}>
        {/* Render Introduction */}
        {introduction && (
          <section className="introduction">
            {/* <h2>Introduction</h2> */}
            <h4>
            <ReactMarkdown>
              {introduction}
            </ReactMarkdown>
            </h4>
          </section>
        )
        }

      {explanation && (
          <section className="introduction">
            {/* <h2>Introduction</h2> */}
            <TextWithBorders text={"Overview"}></TextWithBorders>
            <ReactMarkdown>
              {explanation}
            </ReactMarkdown>
          </section>
        )
        }

    {key_insights && key_insights.length>0 && (
          <section className="key_insights">
            <div style={{background:"#FFF8E1",padding:"1rem 0",borderRadius:"5px"}}>          
             <TextWithBorders icon={<AutoAwesomeIcon style={{color:"#FFD700"}}/>} text={"Key Insights"}></TextWithBorders>
            {/* <h2>Introduction</h2> */}
            <div style={{padding:"1rem"}}>
            {key_insights.map((obj,index)=>
            <div  key={index} style={{display:"flex",gap:"1rem",alignItems:"center"}}> 
              <ChevronRightIcon/>
            <h4 style={{padding:0}}>
              <ReactMarkdown  rehypePlugins={rehypeRaw}>
              {obj}
              </ReactMarkdown>
            </h4>
            </div>
           )}
           </div>
             </div>
          </section>
        )
        }
      
      {next_best_move && (
          <section className="introduction">
            <div style={{background:"var(--colorSuccess)",padding:"1rem 0",borderRadius:"5px"}}>  
             <TextWithBorders icon={<NearMeIcon style={{color:"green"}}/>} text={"Next Best Action"}></TextWithBorders>
             <div style={{padding:"1rem"}}>
             {next_best_move.map((obj,index)=>
                       <div  key={index} style={{display:"flex",gap:"1rem",alignItems:"center"}}> 
                       <ChevronRightIcon/>
                     <h4>
                       <ReactMarkdown rehypePlugins={rehypeRaw}>
                       {obj}
         
                       </ReactMarkdown>
                     </h4>
                     </div>
           )}
           <div style={{padding:"0.5rem 0"}}>
           <FinancialAdvisorConnect variant='contained'/>
           </div>
           
             </div>
             
           </div>
          </section>
        )
        }
        {/* Render each component's explanation and output */}
        {component && component.length > 0 && (
          component.map((item, index) => (
            <section key={index} style={{paddingBottom:"1rem"}}>
              <InfoBox sx={{padding:"0.5rem 0"}} >
              {/* <h3>Explanation</h3> */}
              <TextWithBorders icon={<ArticleIcon/>} text={"Details"}>
              </TextWithBorders>
              
              <div style={{padding:"0.5rem 1rem"}}>
              <ReactMarkdown>
                {item.explanation}
                </ReactMarkdown>
              {/* <h3>Output</h3> */}
              <div className="output-content">
                <ChatComponents react_id={item?.react_id} component={item?.react_component}/>
                {/* {(item?.react_component && componentMap[item?.react_component]) && componentMap[item?.react_component]} */}
                
                {/* {(item?.react_component && !componentMap[item?.react_component]) && renderOutputWithCharts(item?.react_component)}
                 */}
                {item?.output && <ReactMarkdown rehypePlugins={rehypeRaw}>
                {item?.output}
                </ReactMarkdown>}
                {/* {item?.output && renderOutputWithCharts(item?.output)} */}
              </div>
              </div>
              </InfoBox>
            </section>
          ))
        )}
  
        {/* Render the Final Answer */}
        {finalAnswer && (
          <section className="final-answer">
            {/* <h3>Summary</h3> */}
            <div style={{background:"var(--colorInformation)",padding:"1rem",borderRadius:"5px"}}>
            <TextWithBorders icon={<SummarizeIcon/>} text={"Summary"}/>
            <div style={{padding:"1rem"}}>
            <ReactMarkdown>
            {finalAnswer}
            </ReactMarkdown>
            </div>
           
            </div>
          </section>
        )}
  
        {/* Render Related Questions */}
        {learnMore && learnMore.length > 0 && (
          <section className="related-questions">
            <TextWithBorders  icon={<LayersIcon/>} text={"Learn More"}/>
            <div style={{display:"flex",gap:"1rem",flexWrap:"wrap",padding:"1rem"}}>
            {learnMore.map((question, index) => (
              <Link to={question}
               key={index}>
                {question}
              </Link>
            ))}
            </div>
           
          </section>
        )}

        {sources && sources.length > 0 && (
          <section className="source">
            <TextWithBorders icon={<SourceIcon/>} text={"Source"}/>
            <div style={{display:"flex",gap:"1rem",flexWrap:"wrap",padding:"1rem"}}>
            {sources.map((question, index) => (
              <a href={question} target='blank'
               key={index}>
                {question}
              </a>
            ))}
            </div>
           
          </section>
        )}
  
        {relatedQuestions && relatedQuestions.length > 0 && (
          <div style={{marginTop:"2rem"}}>
            <TextWithBorders icon={<QuestionAnswerIcon/>} text={"Related Questions"}/><br/>
            <div style={{display:"flex",gap:"1rem",flexWrap:"wrap"}}>
            {relatedQuestions.map((question, index) => (
              <Button onClick={()=>handleSend(question)} variant='highlight' key={index} className="question-button">
                {question}
              </Button>
            ))}
            </div>
           
          </div>
        )}
      </div>
    );
  };




    
const renderOutputWithCharts = (output) => {
    // Regex to find chart placeholders like ${chart_SomeValue}, {chart_SomeValue}, and chart_SomeValue
    const regex = /(\$\{chart_\w+\}|\{chart_\w+\}|chart_\w+)/g;
  
    // Split the output text by the chart placeholders and retain the delimiters (chart placeholders)
    const parts = output.split(regex);
  
    return parts.map((part, index) => {
      // Test if the current part is a chart placeholder
      if (regex.test(part)) {
        // Strip off any ${}, {}, or just handle `chart_SomeValue`
        const chartKey = part.replace(/\$\{|\{|\}|chart_/g, ''); // Remove `${`, `{`, `}`, and `chart_`
  
        return (
          <React.Fragment key={index}>
            {/* Render the dynamic chart component if it exists */}
            
            
          {componentMap[chartKey] ? 
          <div style={{background:"white",padding:"0.2rem",borderRadius:"5px"}}>
            {componentMap[chartKey]} 
            </div>   
            : <p>Chart not found for {chartKey}</p>
          }
    
          </React.Fragment>
        );
      } else {

        // This part is regular text, render as markdown
        return (
          <React.Fragment key={index}>
            {componentMap[part] ? componentMap[part] :
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
               {part}</ReactMarkdown>}
          </React.Fragment>
        );
      }
    });
  };
  