const initialStateHome = {
  homeDisplay:"Market & News",
  contactUs:false,
  }
  
const initialStateSidebar = {
    sidebarLeftExpanded:true,
    sidebarOpen: false,
    sidebarRightContent: "watchlist",
    showInfoTag: true,
    showSidebarRight:true,
    infoContent:[],
    sidebarLeftMenuOpen:true,
  };

const initialStateSearch = {
  searchClick: false,
  searchTickerHistory:[],
  addPeers:false,
    }


const initialStateChats = {
  chats:{},
  chatId: null,
  user: {},
  unreadChatCount:0,
  unreadChatIds:{},
  chatting:false,
  unreadRecentChat:{},
  chatHomeNotification:true
}

const initialStateWatchlist={
  loadingWatchlist:false,
  createWatchlist:false,
  watchlistMenu:false,
  watchlistData:{},
  watchlistSelected:undefined,
};

const initialStateNotes = {
  loaded:false,
  saved:false,
  names:[],
  nameSel:"",
  data:{},
  dataSel:"",}


export {
  initialStateSearch,
  initialStateHome,
  initialStateSidebar,initialStateWatchlist
  ,initialStateNotes,initialStateChats}