import {Button,} from '@mui/material';
import React from 'react';
import { useAppInfo } from 'AppState';
import { navLinks } from 'nav/menu';
import Logo from 'logo/Logo';
import ProfileSettings from 'nav/ProfileSettings';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';


export default function LeftSidebarMainCollapsed({pageName,handleHover,handleSidebar}) {
  const {dispatchSidebar} = useAppInfo()
  return (
    <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      overflow: 'hidden',
      height: '100%',
    }}
  >
      {/* <Button
      sx={{
        minWidth: '0',
        padding: '0',
      }}
      onMouseEnter={() => dispatchSidebar({ sidebarLeftExpanded: true })}
    >
         <Logo/>

    </Button> */}

    <Button
      sx={{
        minWidth: '0',
        padding: '0',
      }}
      onMouseEnter={() => dispatchSidebar({ sidebarLeftExpanded: true })}
    >
      <MenuOpenIcon style={{ transform: 'rotate(180deg)' }} />
    </Button>

    {navLinks.map((option, index) => (
      <React.Fragment key={index}>
        <Button
          id={`link-${index}`}
          aria-describedby={option.name}
          onPointerEnter={() => handleHover(index)}
          onMouseEnter={() => handleHover(index)}
          onClick={() => {
            if (!option.menu) {
              handleSidebar(option.link);
            }
          }}
          sx={{
            position: 'relative',
            minWidth: '0',
            width: '100%',
            flexDirection: 'column',
            textTransform: 'none',
            background: option.link === pageName && '#F8F8F8',
            color: option.link !== pageName && '#ddd',
            padding: '0.5rem',
            borderRadius: 0,
            '&:hover': {background:option.link === pageName ? '#F8F8F8' :"#fafafa",color:option.link !== pageName && 'black'}
          }}
        >
         {option?.icon}  
        </Button>
      </React.Fragment>
    ))}
    <div
      style={{
        marginTop: 'auto',
        paddingBottom: '0rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',paddingBottom:"0.5rem"
      }}
    >

      <ProfileSettings/>
    </div>
  </div>
  )
}
