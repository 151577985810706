// FinanceProfessional.js
import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  Typography,
  Grid,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Paper,Box,
  Rating
} from '@mui/material';



export function EditModal({edit,handleClose,handleChange,profile,currentSection}){

    return (
        <Dialog open={edit} onClose={handleClose}>
        <DialogTitle>
          Edit {currentSection.charAt(0).toUpperCase() + currentSection.slice(1)}
        </DialogTitle>
        <DialogContent>
          {currentSection && (
            <EditSectionContent
              section={currentSection}
              profile={profile}
              onChange={handleChange}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
}


// Edit Section Content Component
const EditSectionContent = ({ section, profile, onChange }) => {
    
    switch (section) {
      case 'profile':
        return <ProfileEdit profile={profile} onChange={onChange} />;
      case 'about':
        return <ServicesEdit profile={profile} onChange={onChange} />;
      case 'fees':
        return <FeesEdit fees={profile.feeStructure} onChange={onChange} />;
      case 'organization':
        return (
          <TextField
            margin="dense"
            label="Organization"
            name="organization"
            value={profile.organization}
            onChange={(e) => onChange(e)}
            fullWidth
          />
        );
      case 'otherDetails':
        return <OtherDetailsEdit details={profile.otherDetails} onChange={onChange} />;
      default:
        return null;
    }
  };
  



const roles = ["Financial Advisor", "Portfolio Manager", "Mortgage Advisor"];

// Individual Section Edit Components
const ProfileEdit = ({ profile, onChange }) => (
    <div>
      <TextField label="Name" name="name" value={profile.name} onChange={onChange} fullWidth margin="dense" />

      <FormControl fullWidth margin="dense">
        <InputLabel>Role</InputLabel>
        <Select name="role" value={profile.role} onChange={onChange} label="Role">
          {roles.map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField label="City" name="city" value={profile.region.city} onChange={(e) => onChange(e, 'region')} fullWidth />
      <TextField label="State" name="state" value={profile.region.state} onChange={(e) => onChange(e, 'region')} fullWidth />
      <TextField label="Country" name="country" value={profile.region.country} onChange={(e) => onChange(e, 'region')} fullWidth />
    </div>
  );
  
const ServicesEdit = ({profile, onChange }) => (
    <div>
      <TextField label="Description" name="description" value={profile.description} onChange={onChange} fullWidth multiline rows={4} />
      <TextField label="Description" name="description" value={profile.description} onChange={onChange} fullWidth multiline rows={4} />
      <TextField label="Core Expertise" name="coreExpertise" value={profile.servicesProvided.coreExpertise.join(', ')} onChange={(e) => onChange(e, 'servicesProvided')} fullWidth />
      <TextField label="Detail Services" name="detailServices" value={profile.servicesProvided.detailServices.join(', ')} onChange={(e) => onChange(e, 'servicesProvided')} fullWidth />
    </div>
  );
  
  const FeesEdit = ({ fees, onChange }) => (
    <div>
      <TextField label="Hourly Rate" name="hourlyRate" value={fees.hourlyRate} onChange={(e) => onChange(e, 'feeStructure')} fullWidth />
      <TextField label="Project Fee" name="projectFee" value={fees.projectFee} onChange={(e) => onChange(e, 'feeStructure')} fullWidth />
      <TextField label="Retainer" name="retainer" value={fees.retainer} onChange={(e) => onChange(e, 'feeStructure')} fullWidth />
    </div>
  );
  
  const OtherDetailsEdit = ({ details, onChange }) => (
    <div>
      <TextField label="Languages" name="languages" value={details.languages.join(', ')} onChange={(e) => onChange(e, 'otherDetails')} fullWidth />
      <TextField label="Availability" name="availability" value={details.availability} onChange={(e) => onChange(e, 'otherDetails')} fullWidth />
    </div>
  );