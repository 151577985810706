import { useAppInfo } from "AppState"
import { assetTypes, defaultFieldBlank } from "appState/initialStateMyFinancials"
import FieldFormGen from "myFinancials/FormFields/FieldFormGen"
import React, { useMemo, useState } from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { formatNumber } from 'utils/utilGeneral';
import { Button, IconButton } from "@mui/material"
import { useHandleDeleteField } from "myFinancials/FormFields/useFieldForm"
import { Delete } from "@mui/icons-material"
import PopoverButton from "components/Modal/PopoverButton"
import AssetIndividualEdit from "./AssetIndividualEdit"
import CrudModal from "components/Modal/CrudModal"
import CircleScore from "components/Charts/CircleScore"
import UpdateAllocation from "myFinancials/Utils/AllocationUpdate";
import AddAsset from "./AddAsset";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PageMainContentTitle } from "components/Text/Title";
import { MetricSettingsBar } from "components/Template/MetricSettings";
import AssetAllocation from "./AssetAllocation";


export default function AssetDetails(){
  const {stateMyFinancials} = useAppInfo()
  const {assets,currency} = stateMyFinancials
  const currencySymbol = currency?.symbol
  const {handleDeleteField} = useHandleDeleteField({storeName:"assets"})


  const groupedAssets = useMemo(() => {
      const updateAllocationAsset = UpdateAllocation(assets)
      const grouped = Object.values(
        updateAllocationAsset.reduce((acc, obj) => {
          const { type, amount, currentAllocation } = obj;
    
          if (!acc[type]) {
            acc[type] = {
              type: type,
              amount: 0,
              currentAllocation: 0,  // Initialize the allocation accumulator
              assets: [],
            };
          }
    
          acc[type].amount += amount;  // Add up the amount
          acc[type].currentAllocation += Number(currentAllocation);  // Accumulate the allocation
          acc[type].assets.push(obj);  // Maintain the order of assets
    
          return acc;
        }, {})
      );
    
      // Sort the grouped assets based on the order in assetTypes
      return grouped.sort((a, b) => {
        return assetTypes.indexOf(a.type) - assetTypes.indexOf(b.type);
      });
    }, [assets, assetTypes]);
    
  const [confirmDelete,setConfirmDelete] = useState({id:0,title:"",delete:false})
  const [expandedRows, setExpandedRows] = useState(new Set());

  const toggleExpand = (index) => {
    setExpandedRows((prev) => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(index)) {
        newExpanded.delete(index);
      } else {
        newExpanded.add(index);
      }
      return newExpanded;
    });
  };

  const expandAll = () => {
    const allIndexes = new Set(groupedAssets.map((_, index) => index));
    setExpandedRows(allIndexes);
  };

  const collapseAll = () => {
    setExpandedRows(new Set());
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', 
      minWidth: '400px', flex: 1 }}>
       
        <MetricSettingsBar 
        childrenPopover={
        <>
        <Button onClick={expandAll}>Expand All</Button>
        <Button onClick={collapseAll}>Collapse All</Button>
        <AddAsset/>
        </>}
        title="Asset Overview"
            
            ></MetricSettingsBar>
           <AssetAllocation chartHeight="300px" allocationType="type" showDetails={false}/>
        <table style={{tableLayout:"fixed"}}>
          <thead>
            <tr>
              <th style={{width:"250px"}}>Assets</th>
              {expandedRows.size === 0 && <th colSpan={6}></th>}
              {expandedRows.size > 0 && (
                <>
                  <th>Allocation (%)</th>       
                  <th>Name</th>
                  <th>Amount</th>
                  <th>Expected Return (annual)</th>
                  <th>Expected Volatility (annual)</th>
                  <th>Edit/Delete</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {groupedAssets.map((group, index) => (
              <React.Fragment key={index}>
                <tr 
                onClick={(e) =>{e.stopPropagation();toggleExpand(index)} }
                style={{ background: 'white',minWidth:"250px"}}>
                  <td rowSpan={expandedRows.has(index) ? group?.assets.length + 1 : 1}
                  style={{width:"250px"}}
                  >
                    <div style={{ display: 'flex', alignItems: 'center',gap:"0.5rem"}}>
                    <CircleScore 
                    key={index} 
                    tooltipInfo={<>{`Current Allocation: ${group?.currentAllocation}`}</>}
                    title="Allocation" color={"blue"} width="60px" height="60px" 
        totalScore={100} score={group?.currentAllocation}/>
                      <h4 style={{ flex: 1,whiteSpace:"balance"}}>
                        {group.type}: {currencySymbol} {formatNumber(group.amount)}
                      </h4>
                      <Button sx={{ minWidth: '0', padding: '0.25rem', marginLeft: 'auto' }}>
                        {expandedRows.has(index) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </Button>
                    </div>
                  </td>
                  {!expandedRows.has(index) &&
                  <td colSpan={6}>
                    <h5 style={{fontWeight:"bold"}}>Show Details</h5>
                  </td>}
                  
                </tr>
                {expandedRows.has(index) &&
                  group?.assets.map((obj, indexAsset) => (
                    <tr key={indexAsset}>
                      <td>
                      <CircleScore  title="Allocation" color={"blue"} width="40px" height="40px" 
        totalScore={100} score={obj?.currentAllocation}/>
                      </td>
                      <td>{obj.name}</td>
                     
                      <td>
                      {currencySymbol} {formatNumber(obj.amount)}
                      </td>
                      
                      <td>{formatNumber(obj?.return)}</td>
                      <td>{formatNumber(obj?.volatility)}</td>
                      <td>
                        <PopoverButton sxBtn={{ minWidth: '0' }} title='Edit/Details' iconSize='small' 
                        icon='edit'>
                            <AssetIndividualEdit  assetData={obj}/>
                        </PopoverButton>
                        <IconButton disabled={!obj?.edit} onClick={() => setConfirmDelete({ delete: true, id: obj?.id, title: obj?.name })}>
                          <Delete fontSize='small'></Delete>
                        </IconButton>
                      </td>
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <div style={{display:"flex",position:"sticky",bottom:0,zIndex:"10000"}}>
      <AddAsset/>
      </div>
        {confirmDelete?.delete && (
          <CrudModal
            onClose={() => setConfirmDelete({})}
            title={confirmDelete?.title}
            modalType='delete'
            handleDelete={() => handleDeleteField(confirmDelete?.id)}
          />
        )}
      </div>
  )
}

export function AssetDetailsUI(){
  
    const {stateMyFinancials} = useAppInfo()
    const {assets,currency} = stateMyFinancials
    const currencySymbol = currency?.symbol
    const {handleDeleteField} = useHandleDeleteField({storeName:"assets"})

    const groupedAssets = useMemo(() => {
        const updateAllocationAsset = UpdateAllocation(assets)
        const grouped = Object.values(
          updateAllocationAsset.reduce((acc, obj) => {
            const { type, amount, currentAllocation } = obj;
      
            if (!acc[type]) {
              acc[type] = {
                type: type,
                amount: 0,
                currentAllocation: 0,  // Initialize the allocation accumulator
                assets: [],
              };
            }
      
            acc[type].amount += amount;  // Add up the amount
            acc[type].currentAllocation += Number(currentAllocation);  // Accumulate the allocation
            acc[type].assets.push(obj);  // Maintain the order of assets
      
            return acc;
          }, {})
        );
      
        // Sort the grouped assets based on the order in assetTypes
        return grouped.sort((a, b) => {
          return assetTypes.indexOf(a.type) - assetTypes.indexOf(b.type);
        });
      }, [assets, assetTypes]);
      
    const [confirmDelete,setConfirmDelete] = useState({id:0,title:"",delete:false})
    const [expandAsset,setExpandAsset] = useState(true)
    return (
    <div style={{display:"flex",flexDirection:"column",gap:"0.5rem",minWidth:"400px",flex:1,height:"100%"}}>
   <div style={{display:"flex",gap:"0.5rem",background:"#fafafa",padding:"0.25rem",flex:1}}>
    <h5 style={{fontWeight:"bold",color:"gray",width:"60px"}}>Allocation</h5>
   <h5 style={{fontWeight:"bold",color:"gray",flex:1}}>Asset Details</h5><h5 style={{fontWeight:"bold",color:"gray"}}>Settings</h5></div>
    {groupedAssets.map((group, index) => (
  <div key={index} style={{background:"#fafafa",borderRadius:"5px",padding:"0.25rem",display:"flex"}}>
    <div style={{width:"100%"}}>
    
    <div style={{padding:"0.25rem 0",display:"flex",gap:"0.5rem",alignItems:"center"}}>
        <div style={{width:"60px"}} >
        <CircleScore strokeEmpty="white" title="Allocation" color={"blue"} width="60px" height="60px" 
        totalScore={100} score={group?.currentAllocation}/>
     </div>
    <div style={{flex:1}}>
    <h4 style={{fontWeight:"bold"}}>{group.type}: {currencySymbol} {formatNumber(group.amount)}</h4>
    {/* <h5 style={{fontWeight:"bold"}}>Current Allocation: {formatNumber(group?.currentAllocation)}%</h5>
     */}
    </div>

    <Button onClick={()=>setExpandAsset(!expandAsset)} sx={{minWidth:"0",padding:"0.25rem"}}>
        {!expandAsset ? <KeyboardArrowDownIcon/>:<KeyboardArrowUpIcon/>}
            </Button>
    </div>
    {expandAsset && 
    <div  style={{display:"flex",flexDirection:"column",gap:"0.5rem",padding:"0 0.5rem",paddingLeft:"60px",width:"100%",boxSizing:"border-box"}}>
    {group.assets.map((obj, indexAsset) => (
      <div key={indexAsset} style={{display:"flex",width:"100%"
      ,gap:"0.5rem",background:"#f4f4f4",boxSizing:"border-box",borderRadius:"4px",padding:"0.25rem",alignItems:"center"}}>
         <CircleScore strokeEmpty="white"  title="Allocation" color={"blue"} width="40px" height="40px" 
        totalScore={100} score={obj?.currentAllocation}/>
        <div style={{flex:1}}>
          <h4 style={{fontWeight:"bold"}}>{obj.name}: {currencySymbol} {formatNumber(obj.amount)}</h4>
        </div>
        <div>
        <h6 style={{fontWeight:"bold"}}>Allocation</h6>
        <div style={{display:"flex",gap:"0.2rem"}}>
        <div>
        <h4>{formatNumber(Number(obj?.currentAllocation))}%</h4>
        <h6>current</h6>
        </div>
        <div>
        <h4>{formatNumber(obj.targetAllocation)}%</h4>
        <h6>target</h6>
        </div>
        <div>
        <h4>{formatNumber(obj?.differenceAllocation)}%</h4>
        <h6>differenece</h6></div>
          </div>
       
        </div>
        <div>
        <h6 style={{fontWeight:"bold"}}>E(R)</h6>
        <h4>{formatNumber(obj.return)}%</h4>
        <h6>annual</h6>
        </div>
        <div>
        <h6 style={{fontWeight:"bold"}}>E(V)</h6>
        <h4>{formatNumber(obj.volatility)}%</h4>
        <h6>annual</h6>
        </div>
        <PopoverButton 
        sxBtn={{minWidth:"0"}}
        title="Edit/Details"
        iconSize="small"
        variant="highlight"
        icon="edit">
            <AssetIndividualEdit  assetData={obj}/>
        </PopoverButton>
        <IconButton disabled={!obj?.edit} onClick={()=>setConfirmDelete({delete:true,id:obj?.id,title:obj?.name})}><Delete fontSize="small"></Delete> 
        </IconButton>
      </div>
    ))}
    
    </div>}
    </div>
  </div>
))}
   {confirmDelete?.delete &&
    <CrudModal onClose={()=>setConfirmDelete({})} title={confirmDelete?.title} 
    modalType="delete" handleDelete={()=>handleDeleteField(confirmDelete?.id)}></CrudModal> }

    <div style={{display:"flex",marginTop:"auto",position:"sticky",bottom:0,zIndex:"10000"}}>
    <AddAsset/>
    </div>
    
    </div>
    )
  }

export function AssetDetailsMulti(){
  
    const {stateMyFinancials} = useAppInfo()
    const {assets} = stateMyFinancials
  
    return (<FieldFormGen 
      // forecastPeriod={true} 
      date={true} 
      // volatility={true} 
      // returnExpected={true} 
      allocation={false} 
      defaultField={defaultFieldBlank.Asset}
      storeName={"assets"} data={assets} showType={true} 
      typeOptions={assetTypes}/>)
  }