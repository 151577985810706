import React, { useEffect, useRef, useState } from 'react';
import { Typography, Button, List, ListItem, ListItemText, Divider, Tabs, Tab } from '@mui/material';
import useHandleUserEvents from './useHandleUserEvents';
import TextWithBorders from 'components/Text/TextWithBorders';
import TabLine from 'components/Tabs/TabLine';
import MessageTape from './MessageTape';
import { useAppInfo } from 'AppState';

export default function ExploreQuestions() {
    // Manage which subcategory questions are expanded
    const {dispatchAssistant} = useAppInfo()
    const {handleSend} = useHandleUserEvents()
    const [expanded, setExpanded] = useState({});
    const [activeTab, setActiveTab] = useState(0);
  
    // Refs for each category to scroll to
    const categoryRefs = useRef([]);
    const observerRef = useRef();
  
    // Toggle the expansion of a specific subcategory
    const handleToggle = (category, subCategory) => {
      const key = `${category}-${subCategory}`;
      setExpanded((prev) => ({
        ...prev,
        [key]: !prev[key]
      }));
    };
  
    // Handle tab change
    const handleTabChange = (event,newValue) => {
      setActiveTab(newValue);
      categoryRefs.current[newValue].scrollIntoView({top:-150, behavior: 'smooth' });
    };
  
    // Use Intersection Observer to detect which category is in view
    useEffect(() => {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const index = categoryRefs.current.indexOf(entry.target);
              setActiveTab(index);
            }
          });
        },
        { rootMargin: '-50px 0px 50px 0px', threshold: 0.50}
      );
  
      // Observe each category div
      categoryRefs.current.forEach((ref) => {
        if (ref) observerRef.current.observe(ref);
      });
  
      // Cleanup on unmount
      return () => {
        if (observerRef.current) observerRef.current.disconnect();
      };
    }, []);
  
    return (
      <div style={{ width: '100%', boxSizing: 'border-box',height:"100%",overflow:"auto"}}>
        {/* Tabs for categories */}
        
        <h2>Explore Questions</h2>
        <h4 style={{fontWeight:"normal",color:"gray"}}>These are just a few sample questions 
            to get you started...</h4>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          scrollButtons="auto"
          style={{ paddingTop:"1rem",position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1}}
        >
          {questionsData.map((category, index) => (
            <Tab key={index} label={category.category} />
          ))}
        </Tabs>
  
        {questionsData.map((category, index) => (
          <div
            key={index} 
            ref={(el) => (categoryRefs.current[index] = el)}   
            style={{ marginBottom: 20, padding: '1rem 0' }}
          >
            <TextWithBorders sx={{fontSize:"1.2rem",padding:"1rem 0"}} text={category.category}/>

            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
              {category.subCategory.map((sub, idx) => (
                <div
                  key={idx}
                  style={{
                    padding: '1rem',
                    maxWidth: '400px',
                    minWidth: '300px',
                    background: '#fafafa',
                    borderRadius: '5px'
                  }}
                >
                  <Typography variant="h6" style={{ marginBottom: 5, fontSize: '1rem',fontWeight:"bold"}}>
                    {sub.title}
                  </Typography>
                  <div>
                    {/* Show only the first 3 questions unless expanded */}
                    {(expanded[`${category.category}-${sub.title}`]
                      ? sub.questions
                      : sub.questions.slice(0, 3)
                    ).map((question, qIdx) => (
                      <Button
                        onClick={()=>{dispatchAssistant({exploreQuestions:false});handleSend(question);}}
                        key={qIdx}
                        variant="text"
                        fullWidth
                        style={{ fontSize: '0.9rem', textAlign: 'left', justifyContent: 'flex-start'}}
                      >
                        {question}
                      </Button>
                    ))}
                  </div>
                  {sub.questions.length > 3 && (
                    <Button
                      size="small"
                      variant="text"
                      color="primary"
                      onClick={() => handleToggle(category.category, sub.title)}
                    >
                      {expanded[`${category.category}-${sub.title}`] ? 'Show Less' : 'More'}
                    </Button>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
        <MessageTape/>
      </div>
    );
  }





  const questionsData = [
    {
      category: "Personal Finance",
      subCategory: [
          {
              title: "Financial Health",
              questions: [
                "How am I doing financially?",
                "How can I improve my financials?",
                "How can I grow my Networth?",
              ]
            },
            {
              title: "My Situation",
              questions: [
                "What is my Networth",
                "What is my Asset Allocation?",
                "How much do I owe?",
                "How is my portfolio doing?",
                "How much am I burning per month?",
              ]
            },
        {
          title: "Budgeting",
          questions: [
            "How much am I spending per month?",
            "How do I create a budget that works for me?",
            "What are the best strategies to manage monthly expenses?",
            "How do I track my spending effectively?",
            "What should I do if my expenses exceed my income?",
            "How can I adjust my budget to accommodate unexpected expenses?"
          ]
        },
        {
          title: "Saving Strategies",
          questions: [
            "How much am I saving each month?",
            "How much should I save each month?",
            "What are effective ways to build an emergency fund?",
            "What percentage of my income should I allocate towards savings?",
            "Is it better to save or invest extra cash?",
            "How can I save for multiple goals at the same time?"
          ]
        },
      ]
    },
    {
      category: "Life Scenarios",
      subCategory: [
          {
              title: "House Purchase",
              questions: [
                "Can I afford a House with my current financials?",
                "Give me a plan to purchase a house",
                "What are the things I should consider when buying a House?",
              ]
            },
            {
              title: "Child Planning",
              questions: [
                "I am planning to have a kid - can you give me a financial plan?",
                "What are the costs asociated with having a child?",
              ]
            },
        
        {
          title: "Retirement Planning",
          questions: [
            "Give me a retire plan?",
            "Can I retire in next 5 years?",
            "How much should I have in my bank to retire?",
          ]
        },
        {
          title: "Other Scenarios",
          questions: [
            "I want a car and travel annually. Give me a plan.",
            "I am currently renting but planning on buying a house. What is the extra cost?",
          ]
        },
      ]
    },
    { category: "Portfolio Management",
      subCategory: [
      {title: "Portfolio Health",
      questions: [
        "How is my Portfolio Doing?",
        "Am I beating the market?",
        "What are my top holdings?",
        "How should I rebalance my portfolio?",
        "What percentage should I allocate to stocks vs. bonds?",
        "How can I include alternative assets in my portfolio?",
      ]},
      {title: "Enhance Portfolio",
          questions: [
            "Find me opportunities that align my Portfolio Strategy",
            "How should I rebalance my portfolio?",
            "How can I include alternative assets in my portfolio?",
          ]},
      {title: "Risk Management",
          questions: [
            "What is my Risk Tolerance?",
            "What is the best asset allocation for my risk profile?",
            "What should I do if I want to reduce my exposure to market risk?",
            "What percentage should I allocate to stocks vs. bonds?",
  
          ]}]
    },
    {
      category: "Opportunities",
      subCategory: [
        {
          title: "Long Term Investing",
          questions: [
            "Find me opportunities that align with my Portfolio",
            "Show me some undervalued Stocks",
          ]
        },
        {
          title: "Trading",
          questions: [
            "Give me top trending Stocks in the Technology Sector",
            "Give me trending Cryptos",
          ]
        },
        {
          title: "Industry Specific",
          questions: [
            "Top IT stocks",
            "Top Healthcare stocks",
            "Top Energy stocks",
            "Top Consumer Staple stocks",
            "Top Auto stocks",
          ]
        },
      ]
    },
    {
      category: "Market & Economy",
      subCategory: [
        {
          title: "Market Trends",
          questions: [
            "What are the current trends in the stock market?",
            "What is the outlook for the stock market?",
            "What are the biggest risks facing the markets right now?",
          ]
        },
        {
          title: "Market News",
          questions: [
            "Latest Stock News",
            "Latest Crypto News",
            "Latest Forex News",
          ]
        },
        {
          title: "Economy",
          questions: [
            "How is the Economy Doing?",
            "What is the outlook for the Economy?",
            "How do interest rates affect market performance?",
            "How does inflation impact different asset classes?",
          ]
        }
      ]
    },
    {
      category: "Stock Analysis",
      subCategory: [
        {
          title: "Fundamental Analysis",
          questions: [
            "AAPL vs MSFT vs GOOG vs NVDA vs META",
            "Give me NVDA - Discounted Cash Flow Valuation",
            "How does NVDA compare with its Industry?",
            "TSLA latest financials?",
          ]
        },
        {
          title: "Technical Analysis",
          questions: [
            "META price comparison with its peers",
            "NVDA technical indicators",
            "NVDA correlation with its peers",
          ]
        },
        {
          title: "Earning Call Higlights",
          questions: [
            "Give me the earning call highlights for TSLA",
            "Key issues NVDA is having according to its recent earning calls",
            "What is the outlook for AAPL from its recent earning calls?",
            "What are the biggest risks WMT is facing according to its recent earning calls?",
          ]
        }
      ]
    },
    {
      category: "Reports",
      subCategory: [
        {
          title: "My Situation",
          questions: [
            "Give me report on My Financials",
            "Give me report on My Portfolio",
          ]
        },
        {
          title: "Market & Economy",
          questions: [
            "Give me report on the Market",
            "Give me report on the Economy",
          ]
        }
      ]
    }
  
  ];
  