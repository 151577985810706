import React, { useEffect } from "react";
import {useLocation, Outlet, } from "react-router-dom";
import RightSidebar from "./sidebar/RightSidebar/RightSidebar";
import SidebarRightContent from "./sidebar/RightSidebar/SidebarRightContent";
import ChatNotfication from "./chat/ChatNotification";
import AssetModal from "analytics/ticker/AssetModal";
import WatchlistCreateModal from "./watchlist/WatchlistCreateModal";
import SearchModal from "./search/SearchModal";
import useTickerInfo from "./data/ticker/useTickerInfo";
import usePeerFilter from "./peerList/usePeerFilter";
import useKeyboardShortcuts from "./utils/useKeyboardShortcuts";
import useFindWatchlist from "./data/user/watchlist/useFindWatchlist";
import useScreenerStocks from "./data/screener/useScreenerStocks";
import { useAppInfo } from "AppState";
import useSetStockInfo from "analytics/ticker/hooks/useSetStockInfo";
import useSetPortfolio from "myFinancials/useSetPortfolio";
import useSetMyFinancials from "myFinancials/useSetMyFinancials";
import useCheckAlertsMyFinancials from "myFinancials/useCheckAlertsMyFinancials";
import AppNotification from "AppNotification";
import useFindLearnTopics from "data/resource/useFindLearnTopics";
import useSecIndList from "data/industry/useSecIndList";
import useIndSecDetails from "analytics/industry/useIndSecDetails";
import useSetTickerInfo from "analytics/ticker/hooks/useSetTickerInfo";
import useScreenerCrypto from "data/screener/useScreenerCrypto";
import NoTickerInfoModal from "components/NoTickerInfoModal";
import useSetMarketToday from './analytics/market/useSetMarketToday';
import useFindReport from "data/user/reports/useFindReport";
import useAddDefaultWatchlist from "data/user/watchlist/useAddDefaultWatchlist";
import AddPeersModal from "peerList/AddPeersModal";
import LeftSidebarMain from "sidebar/LeftSidebar/LeftSidebarMain";
import NavNew from "nav/NavNew";
import TSAssistant from "chatGPT/TSAssistant";
import LeftSidebarMainExpanded from "sidebar/LeftSidebar/LeftSidebarMainExpanded";
import HomePageChat from "home/HomePageChat";
import useFindChatAI from "data/user/chatAI/useFindChatAI";


export default function AppLayoutDashboard() {

  const {stateSidebar,stateAssistant,dispatchSidebar} = useAppInfo()
  const location = useLocation();
  const { pathname } = useLocation();
  const pageName = location.pathname.split("/")[1]
  const pageAbout = /(\/|^)(About|Pricing|Features|Contact|Product|Terms|Disclaimer|Support|Profile)(\/|$)/i.test(pageName);
  const { sidebarLeftExpanded } = stateSidebar;
  const {chats} = stateAssistant
  
  useFindChatAI()
  useTickerInfo()
  useSetTickerInfo()
  useSetStockInfo()
  useSetMarketToday()
  
  useScreenerCrypto()
  
  //industrySector
  useSecIndList();
  useIndSecDetails()
  useFindWatchlist();
  useAddDefaultWatchlist()
  usePeerFilter();
  useScreenerStocks();

  useFindReport()
  
  useSetMyFinancials()
  useSetPortfolio()  
  useCheckAlertsMyFinancials()
  useFindLearnTopics()
  useKeyboardShortcuts();


  useEffect(() => {
    const contentWrapper = document.getElementById('contentWrapper');
    if (contentWrapper) {
      contentWrapper.scrollTo(0, 0);
    }
    window.scrollTo(0,0)
  }, [pathname]);

  useEffect(()=>{
    if (chats.length > 0){
      dispatchSidebar({ sidebarLeftExpanded: false})
    }

    // else if (["Home",""].includes(pageName)){
    //   dispatchSidebar({ sidebarLeftExpanded: true })
    // }
  },[pageName,chats])



  return (
        <>
          

          <div style={{display:"flex",width:"100%",height:"100%"}}>

          
          <LeftSidebarMain/>

            <div className="appContentContainer" 
              style={{
              marginRight:!stateSidebar.showSidebarRight && 0,  
              marginLeft:(stateSidebar.sidebarLeftExpanded) && 0,
              width:(stateSidebar.sidebarLeftExpanded) && "auto",minWidth:(stateSidebar.sidebarLeftExpanded) && 0}}>
              
              <div id="appContent" className="appContent">
              <NavNew/>
                <div id="contentWrapper" className="contentWrapper">           
                  <Outlet/>
                 </div>

                 {(!pageAbout && !["Home","","ReportBuilder","home","AskAI"].includes(pageName)) && <TSAssistant/>}
              </div>
              {(!pageAbout && stateSidebar.sidebarOpen && stateSidebar.showSidebarRight) && 
              <SidebarRightContent />}
            </div>
            {<RightSidebar />}

            <AssetModal />
            <NoTickerInfoModal/>
            <SearchModal/>
            <AddPeersModal/>
            <WatchlistCreateModal/>
            <AppNotification/>
            <ChatNotfication/>
            </div>
      </>
  );
}

