import { Button, IconButton, Typography } from '@mui/material'
import { useAppInfo } from 'AppState'
import ChatContent from 'chatGPT/ChatContent'
import React, { useEffect, useState } from 'react'
import MessageBar from 'chatGPT/MessageBar'
import ExploreQuestions from './ExploreQuestions'
import ExploreAssitants from './ExploreAssistants'
import ChatAIMenu, { ChatAIMenuLeftSidebar } from './ChatAIMenu'
import Modal from 'components/Modal/Modal'


export default function TSAssistantMainLayout() {

    const {stateApp,stateAssistant,dispatchAssistant} = useAppInfo()
    const {chats,chatHistory,chatWindow,exploreQuestions,exploreAssistants,assistantSelected} = stateAssistant
  
  return (
    <div style={{height:"100%",width:"100%",boxSizing:"border-box",display:"flex"}}> 

        {/* <ChatAIMenuLeftSidebar/> */}

        <div style={{flex:1,padding:"0",overflow:"auto",display:"flex",flexDirection:"column"}}>
        <div style={{flex:1,padding:"1rem"}}>
        {/* {(!chatWindow && exploreQuestions) && <ExploreQuestions/>}
         */}
        {chatWindow && <ChatContent/>}
        </div>
        <div style={{
         position:"sticky",background:"white",display:"flex"
         ,alignItems:"center",bottom:0,width:"100%"
         ,justifyContent:"center",padding:"0.5rem 0 2rem 0",zIndex:100000,boxSizing:"border-box"}}>
         <MessageBar/>
         
       </div>

       </div>
       {(exploreQuestions) && 
       <Modal width="80vw" height="80vh" 
       sx={{borderRadius:"5px"}}
       onClose={()=>dispatchAssistant({exploreQuestions:false})}> 
       
 <ExploreQuestions/>
       </Modal>
       }
       {(exploreAssistants) && 
       <Modal width="80vw" height="80vh" 
       sx={{borderRadius:"5px"}} onClose={()=>dispatchAssistant({exploreAssistants:false})}> 
       <div style={{height:"100%",width:"100%",overflow:"auto"}}>

       <ExploreAssitants/>
       </div>
       </Modal>
       }

        </div>
  )
}
