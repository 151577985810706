import React, { useEffect, useMemo } from 'react'
import useFetchAssistant from './useFetchAssistant';
import { useAppInfo } from 'AppState';
import { generateUniqueId } from 'utils/utilGeneral';
import { useLocation, useNavigate } from 'react-router-dom';
import { assistantDetails, assistants, openaiAPI } from 'appState/initialStateAssistant';
import useFetchResponse from './useFetchResponse';
import useAddChatAI from 'data/user/chatAI/useAddChatAI';

export default function useHandleUserEvents() {
    const {dispatchAssistant,stateAssistant,stateApp,dispatchApp} = useAppInfo()
  const {isLoggedIn} = stateApp
  const {fetchAssistant} = useFetchAssistant()
  const {fetchResponse} = useFetchResponse()
  const {chats,voiceRecording,componentProps,questionChat,chatWindow,runId,threadId,chatStatus,assistantSelected} = stateAssistant
  const navigate = useNavigate()
  const location = useLocation();
  const pageName = location.pathname.split("/")[1]
  const pageHome = ["Home","","home"].includes(pageName)
  const assistantSelectedName = useMemo(()=>assistantDetails.filter(obj=>obj.id === assistantSelected)[0]?.name,[assistantSelected])
  const {addChatAI} = useAddChatAI()
  const runIdUpdated = useMemo(()=>{return runId},[runId])


  async function cancelRun({ type = "newChat", threadId, runId } = {}) {
    try {
      if (threadId && runId) {
        await openaiAPI.beta.threads.runs.cancel(threadId, runId);
      } else {
        console.warn('Thread ID and Run ID are required to cancel a run.');
      }
    } catch (error) {
      console.error('Error cancelling run:', error);
    } finally {
      const payload = {
        loading: false,
        threadId: null,
        runId: null,
      };
  
      if (type === "newChat") {
        payload.chats = [];
      }
  
      dispatchAssistant(payload);
    }
  }
  
  function handleStopChat(){
    cancelRun({threadId, runId:runIdUpdated,type:"stop"})
    dispatchAssistant({chatStatus:"stop"})

  }

  function handleMessage(message){
    dispatchAssistant({chatGptMessage:message})
  }
  
  async function handleSend(message) {
    let chatUpdate = [...chats]
    let questionChatUpdate = [...questionChat]
    handleChatWindow(true)
    if (!isLoggedIn){
      dispatchApp({"loginModal":true})
    }
    else{
    if (pageHome){
      navigate("/AskAI")
      if (chats.length>0 || chatStatus === "running" || runId || threadId){
        
        await cancelRun({threadId, runId:runIdUpdated})
        
        const chatId = generateUniqueId()
        const date = new Date()
        const chatHistory = {chatId,label:questionChat[0],chats,questionChat,componentProps,date}
        chatUpdate = []
      dispatchAssistant({chatHistory})
      addChatAI({chatDetails:[chatHistory]})
      }
    }
    if (!message) {
      const chatUpdateFinal = [...chatUpdate, { role: "assistant", content: "Hey there, how can I help you today?" }];
      dispatchAssistant({chats:chatUpdateFinal,chatStatus:"idle"})
    } 
    else {
      const updatedChats = [...chatUpdate, { role: "user", content: message }, { role: "assistant", content: "#?$?#" }];
      dispatchAssistant({chats:updatedChats,chatGptMessage:"",questionHistory:message,questionChat:[...questionChatUpdate,message]})
      if (assistantSelected !== assistants.Facilitator){
        fetchResponse({message,assistantId:assistantSelected,assistantName:assistantSelectedName,chats:updatedChats})
      }
      else{
        fetchAssistant({message,chats:updatedChats})
      }
      
      // fetchResponse(message)
    }
    }
  }


  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

let recognition;

if (SpeechRecognition) {
  recognition = new SpeechRecognition();
}

  const handleVoiceRecording = () => {
    if (!recognition) {
      alert("Voice recognition is not supported in this browser.");
      return;
    }
  
    if (voiceRecording) {
      recognition.stop();
      dispatchAssistant({ voiceRecording: false });
    } else {
      recognition.start();
      recognition.onstart = () => {
        dispatchAssistant({ voiceRecording: true });
      };
      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        dispatchAssistant({ chatGptMessage: transcript });
        setTimeout(() => {
          dispatchAssistant({ showContent: true });
          handleSend(transcript);
        }, 100);
      };
      recognition.onend = () => {
        dispatchAssistant({ voiceRecording: false });
      };
    }
  };
  


  function UpdateChatHistory(){
    if (questionChat.length>0){
      const chatId = generateUniqueId()
      const date = new Date()
      const chatHistory = {chatId,label:questionChat[0],chats,questionChat,componentProps,date}
    dispatchAssistant({chatStatus:"newChat",componentProps:[],questionChat:[],chatHistory})
    addChatAI({chatDetails:[chatHistory]})
    }
   else{
    dispatchAssistant({chatStatus:"newChat",componentProps:[],questionChat:[]})
   }
    
  }

  
  async function handleNewChat() {
    await cancelRun({threadId, runId})
    handleChatWindow(true)
    UpdateChatHistory()
    
  }

  function handleShowContent(v){
    dispatchAssistant({showContent:v})
  }

  function handleChatWindow(v){
    dispatchAssistant({chatWindow:v})
  }

  function handleExploreQuestions(v){
    dispatchAssistant({exploreAssistants:false,exploreQuestions:true})
  }


  
  function handleAssistantSelectionWindow(){
    dispatchAssistant({exploreAssistants:true,exploreQuestions:false})

  }

  return {handleSend,handleVoiceRecording,handleShowContent,handleMessage,handleNewChat,
    handleStopChat,handleExploreQuestions,handleAssistantSelectionWindow,UpdateChatHistory}
}



  // useEffect(() => {
  //     if ((chatStatus === "stop" || chatStatus === "newChat") && runId) {
  //       cancelRun(threadId, runId)
  //         .then(() => {
  //           // If it's "newChat", reset everything
  //           if (chatStatus === "newChat") {
  //             dispatchAssistant({chats:[],threadId:null})
  //           } 
  //         })
  //         .catch((error) => console.error("Error while cancelling the run", error));
  //     }
  //     if ((chatStatus === "newChat") && !runId) {
  //         dispatchAssistant({chats:[],threadId:null})
  //       }
  //   }, [chatStatus, runId]);
