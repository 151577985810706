import React, { useEffect, useState } from 'react';
import SearchNav from './SearchNav';
import { Button, Typography} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { navLinks } from './menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppInfo } from 'AppState';
import HoverMenu from 'sidebar/LeftSidebar/HoverMenu';
import Logo from 'logo/Logo';
import ChatAIMenu from 'chatGPT/ChatAIMenu';


export default function NavNew() {
  const { dispatchSidebar, stateSidebar } = useAppInfo();
  const {sidebarLeftExpanded} = stateSidebar
  const navigate = useNavigate();
    const location = useLocation();
    const pageName = location.pathname.split('/')[1];
    const subPage = location.pathname.split('/')[2];
    const currentPage = navLinks.find((page) => page.link === pageName);
    const currentSubPage = currentPage?.menu?.find((sub) => sub.link === subPage);
    const menu = currentPage?.menu ? currentPage?.menu.map(obj=>obj.name) : []
    const pageAbout = /(\/|^)(About|Pricing|Features|Contact|Product|Terms|Disclaimer|Support|Profile)(\/|$)/i.test(pageName);
   
    const [showMenu,setShowMenu] = useState(false)

    const handleSubPageChange = (v) => {
      const selectedSubPage = currentPage?.menu?.find((sub) => sub.name === v)?.link;
      navigate(`${pageName}/${selectedSubPage}`);
    };

    useEffect(()=>{
      setShowMenu(false)
    },[sidebarLeftExpanded])

  return (
    <div className="nav" style={{background:"white"}}>
    <div className="nav_wrapper">

    <>

   {(["Home",""].includes(pageName) || pageAbout) && !sidebarLeftExpanded &&
   <div className='gradient_text' style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
            <Logo height='25px' width="150px" type='long'/>
            <Typography fontSize={"0.8rem"}>Beta</Typography>
        </div>}


      {(!["Home",""].includes(pageName) && !pageAbout) && 
      <>
      <Button 
      startIcon={currentPage?.icon}
      onClick={()=>setShowMenu(true)}
      style={{display:"flex",alignItems:"center",padding:"0 0.5rem"}}>
    <h3 style={{color:"var(--selectBlue)"}}>{currentPage?.name}</h3>
    {currentPage?.menu && 
    <ExpandMoreIcon/>}
      
      </Button>

{showMenu && <HoverMenu navMenuSelected={currentPage} 
handleSidebar={(v)=>{setShowMenu(false);navigate(v)}}
handleMouseLeave={()=>setShowMenu(false)}/>}
</>
      }

      {pageName === "AskAI" && <ChatAIMenu/>}
      

      <div style={{marginLeft:"auto",display: 'flex', alignItems: 'center',gap:"0.75rem",padding:"0 0.5rem"}}>


      
      <SearchNav />
        
      {/* <SelectMarket label={false}/> */}
      
        {/* <IconButton>
        <NotificationsNoneIcon sx={{color:"gray"}}/>
        </IconButton> */}

      </div>
      
      
    </>

     
   </div>
   
    </div>
  );
}
