
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {
    Settings,
    TrendingUp,
  } from '@mui/icons-material';
  import AbcIcon from '@mui/icons-material/Abc';
  import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
  import AccountTreeIcon from '@mui/icons-material/AccountTree';
  import ArticleIcon from '@mui/icons-material/Article';
  import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
  import BeenhereIcon from '@mui/icons-material/Beenhere';
  import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
  import ChecklistIcon from '@mui/icons-material/Checklist';
  import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
  import CompressIcon from '@mui/icons-material/Compress';
  import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
  import ExploreIcon from '@mui/icons-material/Explore';
  import ExtensionIcon from '@mui/icons-material/Extension';
  import FeedIcon from '@mui/icons-material/Feed';
  import FilePresentIcon from '@mui/icons-material/FilePresent';
  import GestureIcon from '@mui/icons-material/Gesture';
  import GppMaybeIcon from '@mui/icons-material/GppMaybe';
  import LanguageIcon from '@mui/icons-material/Language';
  import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
  import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
  import MultipleStopIcon from '@mui/icons-material/MultipleStop';
  import PagesIcon from '@mui/icons-material/Pages';
  import PieChartIcon from '@mui/icons-material/PieChart';
  import PixIcon from '@mui/icons-material/Pix';
  import PrecisionManufacturingIcon
    from '@mui/icons-material/PrecisionManufacturing';
  import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
  import QueryStatsIcon from '@mui/icons-material/QueryStats';
  import SavingsIcon from '@mui/icons-material/Savings';
  import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
  import SsidChartIcon from '@mui/icons-material/SsidChart';
  import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
  import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
  import TableChartIcon from '@mui/icons-material/TableChart';
  import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
  import TokenIcon from '@mui/icons-material/Token';
  import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
  import TuneIcon from '@mui/icons-material/Tune';
  import WarningIcon from '@mui/icons-material/Warning';
  import SpaIcon from '@mui/icons-material/Spa';
  import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
  import HomeIcon from '@mui/icons-material/Home';
  import AdjustIcon from '@mui/icons-material/Adjust';
  import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
  import AutoStoriesIcon from '@mui/icons-material/AutoStories';
  import ReviewsIcon from '@mui/icons-material/Reviews';
  import ThreePIcon from '@mui/icons-material/ThreeP';

  function Icon({imgSrc,height="25px",width="25px"}){
    return(<div style={{width:width,height:height}}>
     <img src={imgSrc} style={{objectFit:"cover"}} width={"100%"} height={"100%"}>
       </img></div>)
   }
  
export const menuIcons = {
    "News":<FeedIcon/>,
    "Calendar":<CalendarMonthIcon/>,
    "MandA":<CompressIcon/>,
    "Merger & Acquisitions":<CompressIcon/>,
    "Filings":<FilePresentIcon/>,
    "TradingActivities":<CompareArrowsIcon/>,
    "Trading Activities":<CompareArrowsIcon/>,
    "Profile":<AccountBoxIcon/>,
    "Investment Portfolio":<PieChartIcon/>,
    "Portfolio":<PieChartIcon/>,
    "Scenarios":<QueryStatsIcon/>,
    "RiskManagement":<GppMaybeIcon/>,
    "Risk Management":<GppMaybeIcon/>,
    "Asset":<SpaIcon/>,
    "Liability":<AccountBalanceWalletIcon/>,
    "Goals":<CrisisAlertIcon/>,
    "Scenarios":<QueryStatsIcon/>,
    "Guide":<TipsAndUpdatesIcon/>,
    "Learn":<AutoStoriesIcon/>,
    "Education":<LocalLibraryIcon/>,
    "Mind Map":<PsychologyAltIcon/>,
    "Glossary":<AbcIcon/>,
    "Networth":<PixIcon/>,
    "Cashflow":<AttachMoneyIcon/>,
    "Alerts":<WarningIcon/>,
    "Ticker":<TokenIcon/>,
    "Industry":<PrecisionManufacturingIcon/>,
    "Industry/Sector":<PrecisionManufacturingIcon/>,
    "Sector & Industry":<PrecisionManufacturingIcon/>,
    "Market":<LanguageIcon/>,
    "Economy":<AccountBalanceIcon/>,
    "Stocks":<Icon imgSrc={"/enterprise.png"}/>,
    "Crypto":<Icon imgSrc={"/cryptocurrency.png"}/>,
    "Etf":<Icon imgSrc={"/etfIcon.png"}/>,
    "Bonds":<Icon imgSrc={"/bonds.png"}/>,
    "Forex":<Icon imgSrc={"/forex.png"}/>,
    "Order": <LibraryBooksIcon/>,
    "Trade Conditions": <ChecklistIcon/>,
    "Technicals": <TroubleshootIcon/>,
    "Visuals": <ScatterPlotIcon/>,
    "Checklist": <ChecklistIcon/>,
    "Transactions": <TableChartIcon/>,
    "Chart":<SsidChartIcon/>,
    "Explore":<ExploreIcon/>,
    "Flow":<AccountTreeIcon/>,
    "Trade":<TrendingUp/>,
    "Top Investors":<SupervisedUserCircleIcon/>,
    "Fit":<ExtensionIcon/>,
    "Portfolio Fit":<ExtensionIcon/>,
    "Investment Preferences":<TuneIcon/>,
    "InvestmentPreferences":<TuneIcon/>,
    "Report Builder":<PagesIcon/>,
    "Report":<ArticleIcon/>,
    "Investment":<SavingsIcon/>,
    "Trading":<MultipleStopIcon/>,
     "Settings":<Settings/>,
     "Filter":<TuneIcon/>,
     "Doodle":<GestureIcon/>,
     "My Finanicals":<></>,
    "Home":<HomeIcon/>,
    "Opportunity":<AdjustIcon/>,
    "Health":<HealthAndSafetyIcon/>,
    "ChatAI":<ReviewsIcon/>,
    "AskProfessional":<ThreePIcon/>
     
  }
  