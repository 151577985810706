import { Button, IconButton, Typography } from '@mui/material'
import { useAppInfo } from 'AppState'
import ChatContent from 'chatGPT/ChatContent'
import DefaultMessageBox from 'chatGPT/DefaultMessageBox'
import MessageBarBig from 'chatGPT/MessageBarBig'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { generateUniqueId, getGreeting } from 'utils/utilGeneral'
import useHandleUserEvents from 'chatGPT/useHandleUserEvents'
import ProfileCompletion from 'myFinancials/ProfileCompletion'
import MyFinancialHealthScore from 'myFinancials/MyFinancialHealthScore'
import GoalsSuccessRate from 'myFinancials/Goals/GoalsSuccessRate'
import AlertButton from 'myFinancials/AlertButton'
import PageHeaderIcons from 'nav/PageHeaderIcons'
import MessageTape from 'chatGPT/MessageTape'
import FinanceProfessional from 'finanaceProfessional/FinanceProfessional'


export default function HomePageChat() {
  const {stateApp,stateAssistant,dispatchAssistant} = useAppInfo()
  const {chats,chatHistory,runId,questionChat,componentProps} = stateAssistant
  const navigate = useNavigate()
  const {handleShowContent,handleNewChat,UpdateChatHistory} = useHandleUserEvents()
 
  // useEffect(()=>{
  //   UpdateChatHistory()
  // },[])

  const homeNav = ["About","Contact","Product","Pricing","Profile","Support","Terms","Disclaimer"]
    
  function handleNavigateHome(v){
    navigate(`/${v}`)
  }


  return (
    <>
    <div style={{display:"flex",flexDirection:"column",minHeight:"100%",boxSizing:"border-box"}}>
        
    <div style={{padding:"0.25rem 1rem"
        ,position:"sticky",top:0,width:"100%",zIndex:100000,boxSizing:"border-box"
        ,background:"white",display:"flex",gap:"1rem",flexWrap:"wrap",alignItems:"center"}}>
        
        <div style={{flex:1}}>

        </div>
       
        <div style={{display:"flex",gap:"1rem"}}>
        <ProfileCompletion/>
    <MyFinancialHealthScore displayType='icon'/>
    <GoalsSuccessRate displayType='icon'/>
    <AlertButton displayType='header'/>
    <PageHeaderIcons name='Guide' guideType={"All"}/>
          </div>

        
      </div>

   
        <div style={{width:"100%",display:"flex",alignItems:"center"
        ,flexDirection:"column",padding:"1rem",boxSizing:"border-box"
        ,paddingTop:"50px",gap:"2rem",flex:1}}>
      <Typography variant='h4' sx={{ fontWeight: "bold", margin: 0, padding: 0,textAlign:"center"}}>
            TakeStock<br/><span style={{color:"green"}}>of your financial situation</span>
          </Typography>

        <div style={{width:"50%",maxWidth:"800px",minWidth:"400px"}}>
        <MessageBarBig/>
        </div>
        
        <div style={{flex:1}}>   
        <div style={{display:"flex",gap:"1rem",flexWrap:"wrap",justifyContent:"center"}}>
             
            <DefaultMessageBox/>
           
            </div>
            </div>
        <MessageTape/>
        </div>
       
      
<div style={{
        position:"sticky",background:"white",display:"flex"
        ,alignItems:"center",bottom:0,width:"100%"
        ,justifyContent:"center",paddingBottom:"0.25rem",zIndex:100000,boxSizing:"border-box",gap:"1rem"}}>
      {homeNav.map(obj=>
              <Button sx={{padding:"0.25rem",color:"gray"}} onClick={()=>handleNavigateHome(obj)} key={obj}>
                {obj}
              </Button>
            )}</div>
    </div>
  </>
  )
}
