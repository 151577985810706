import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  Typography,
  Grid,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Paper,Box,
  Rating
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';


function EditDetails({onClick,sx={}}){
    return (
        <IconButton 
            onClick={onClick}
            style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer',...sx}}>
            <EditIcon/>
            </IconButton>
            
    )
}

export function ProfileBanner({profile,handleEdit}) {
  return (
    <Paper style={{ position: 'relative' }}>
    <Box
      sx={{
        height: 200,
        backgroundImage: `url(${profile.bannerImage || 'defaultBanner.jpg'})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        background: '#f7f7f7',
        borderRadius: '5px 5px 0 0',
      }}
    >
      <Avatar
        src={profile.profilePicture}
        alt={profile.name}
        sx={{
          width: 150,
          height: 150,
          position: 'absolute',
          bottom: -35,
          marginLeft: '30px',
          border: '4px solid white',
        }}
      />
      {/* Profile Picture and Name */}

      <EditDetails sx={{top:"105%"}} onClick={()=>handleEdit("profile")}/>
    </Box>

    <Box
      style={{
        marginTop: '50px',
        padding: '0 30px 30px 30px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position:"relative"
      }}
    >
      <div style={{ flex: 1 }}>
        <Typography variant="h4">{profile.name || 'Name'}</Typography>
        <Typography variant="h6">{profile.role || 'Role'}</Typography>
      </div>
      <div>
      <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
          {`${profile.region.city || ''}, ${profile.region.state || ''}, ${
            profile.region.country || ''
          }`}
        </Typography>
        <Typography variant="body1">
          {`Email: ${profile.contactDetails.email}`}
        </Typography>
      </div>
    
      <div style={{paddingRight:"5rem"}}>
        <Rating
          name="read-only"
          value={profile.rating || 0}
          readOnly
          precision={0.5}
        />
        <Typography variant="h6" sx={{color:profile.certified?"green":"red"}}>
          {profile.certified ? 'Certified' : 'Not Certfied'}
        </Typography>
      </div>
     
    </Box>
  </Paper>

  )
}


export function ProfileSection({title,section,children,handleEdit}){
    return (
        <Paper elevation={1} sx={{ p: 3, position: 'relative' }}>
        {/* Edit Icon at top-right corner */}
        <EditDetails onClick={()=>handleEdit(section)}/>
        
        <Typography variant="h6" gutterBottom>
           {title}
          </Typography>
          
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {children}
        </div>
      </Paper>
    )
}


export function AboutSection({profile,handleEdit}){
    return (
        <ProfileSection title={"About"} section={"about"} handleEdit={handleEdit}>
         <Typography variant="body1" gutterBottom>
            {profile.description}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Organization
          </Typography>
          <Typography variant="body1" gutterBottom>
            {profile.organization || 'N/A'}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Services
          </Typography>
          <Typography variant="body1" gutterBottom>
            {profile.servicesProvided.detailServices.join(', ') || 'N/A'}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Core Expertise
          </Typography>
          <Typography variant="body1">
            {profile.servicesProvided.coreExpertise.join(', ') || 'N/A'}
          </Typography>
        </ProfileSection>
    )
}

export function FeesSection({profile,handleEdit}){
    return (
        <ProfileSection title={"Fees"} section={"fees"} handleEdit={handleEdit}>
            <Typography variant="body1">
                Hourly Rate: {profile.feeStructure.hourlyRate || 'N/A'}
              </Typography>
              <Typography variant="body1">
                Project Fee: {profile.feeStructure.projectFee || 'N/A'}
              </Typography>
              <Typography variant="body1">
                Retainer: {profile.feeStructure.retainer || 'N/A'}
              </Typography>
          </ProfileSection>

    )
}

export function QualificationSection ({profile,handleEdit}){
    return (  <ProfileSection title={"Qualification"} section={"qualification"} handleEdit={handleEdit}>
          <Typography variant="body1">
            {profile.qualifications || 'N/A'}
          </Typography>
      </ProfileSection>)
}

export function CertificationSection ({profile,handleEdit}){
    return (  <ProfileSection title={"Certification"} section={"certification"} handleEdit={handleEdit}>
          <Typography variant="body1">
                {profile.certifications || 'N/A'}
              </Typography>
      </ProfileSection>)
}

export function FAQSection ({profile,handleEdit}){
    return (  <ProfileSection title={"FAQ"} section={"faq"} handleEdit={handleEdit}>
          <Typography variant="body1">
                {profile.faq || 'N/A'}
              </Typography>
      </ProfileSection>)
}

