import useHandleTickerChange from 'analytics/ticker/hooks/useHandleTickerChange';
import { useAppInfo } from 'AppState';
import axios from 'axios';
import { dfin, metricsGrowth } from 'content/constants';
import { eodHistorialApiKey } from 'data/keys';
import React, { useMemo } from 'react'
import { generateUniqueId, getSpecificArrayObjKeys } from 'utils/utilGeneral';
import fuzzyMatchingText from './useFuzzyMatchingText';
import { getTechnicalRating } from 'data/technicals/useTechnicalRating';
import { fetchPerformanceOverview } from 'data/technicals/usePerformanceOverview';

// Use this assistant to get common ticker analytics like Price, Performance, etc


async function getPriceInfo(tickerSel) {
    let price = {open:0}
    try {
      const responseMetric = await axios(
        `https://eodhd.com/api/real-time/${tickerSel}?api_token=${eodHistorialApiKey}&fmt=json`
      );
      const priceInfo = responseMetric.data
      for (const key in priceInfo) {
        // Check if the key is not "code" or "timestamp"
        if (key !== "code" && key !== "timestamp") {
          // Convert the value to float, or NaN if it's "NA"
          priceInfo[key] = parseFloat(priceInfo[key]) || 0.00;
        }
      }
      price = priceInfo
    } catch (error) {
      console.error(error);
    }
    return {price}
  }


export default function useAssistantTickerAnalyst() {
    const {stateTicker,stateScreener,dispatchTicker,dispatchSidebar,dispatchAssistant} = useAppInfo()
    
    const { tickerList, tickerListYf } = useMemo(() => {
      const tickerList = stateTicker.tickerInfo
        .map((obj) => obj[dfin.ticker])
        .filter((ticker) => ticker !== null);
    
      const tickerListYf = stateTicker.tickerInfo
        .map((obj) => obj[dfin.yfTicker])
        .filter((ticker) => ticker !== null);
    
      return { tickerList, tickerListYf };
    }, [stateTicker.tickerInfo]);

    const handleTickerChange = useHandleTickerChange()
   
    async function GetTickerBuySel({ticker}){
      // const tickerList_data = [ticker]
      const matchingTickers = fuzzyMatchingText(tickerList,[ticker])
      const tickerYF = fuzzyMatchingText(tickerListYf,[ticker])
      const tickerList_data = tickerYF.length > 0 ? tickerYF : matchingTickers.length > 0 ? matchingTickers[0]: [ticker]

      // console.log(matchingTickers,tickerYF,tickerList_data)
      if (matchingTickers.length > 0){
        handleTickerChange({ticker:matchingTickers[0],modal:false,goToPage:false})
      }
      else{
        handleTickerChange({ticker:ticker,modal:false,goToPage:false})
      }
      
      const performanceOverview = await fetchPerformanceOverview({tickerList: tickerList_data,benchmark:"SPY"})

      const technicalRating = await getTechnicalRating({tickerList: tickerList_data})
    
      const react_id = generateUniqueId()
      dispatchAssistant({componentProps:{id:react_id,ticker}})
      const info = [{react_id,"react_component":"TickerBuySellCondition"},
        {key_highlights:{technicalRating,performanceOverview}},{output:""}]
  
      return info 
      
    }
    
    function GetTickerVsPeers({ tickers,component}) {

      const matchingTickers = fuzzyMatchingText(tickerList,tickers)
      // Fetch the price info for the ticker
      if (matchingTickers.length > 0){
      handleTickerChange({ticker:matchingTickers[0],modal:false,goToPage:false})
      if (matchingTickers.length>1){
      dispatchTicker({"peerSelected":matchingTickers,"peerSelectedDisplay":matchingTickers,peersIn:"CUSTOM"});}
      }

      else{
        handleTickerChange({ticker:tickers[0],modal:false,goToPage:false})
        if (tickers.length>1){
          dispatchTicker({"peerSelected":matchingTickers,"peerSelectedDisplay":matchingTickers,peersIn:"CUSTOM"})
          }
      }
      
     

      const infoOptions = {
        "Price vs Peers": "PriceVsPeers",
        "Performance": "Performance",
        "Volatility": "Volatility",
        "Correlation": "Correlation",
        "Technical Indicators": "TechnicalIndicators",
        "Stats vs Peers":"StatsVsPeers",
        "Scatter Plot":"Scatter",
        "Financials vs Peers":"FinancialsVsPeers",
        "All":"TickerVsPeers",
      };

      const filteredComponents = component.includes("All") ? {react_component:infoOptions.All} : Object.keys(infoOptions)
      .filter(key => component.includes(key))
      .map(key => infoOptions[key]);
      const info  = {react_component: filteredComponents,output:""}
 
      return info;
    }

    function GetTickerVsEconomy({ ticker }) {
      // Fetch the price info for the ticker
   
      handleTickerChange({ticker})
      const info  = {"react_component":"TickerVsEconomy"}
          
      return info;
    }

    function GetTickerVsBenchmark({ ticker }) {
      // Fetch the price info for the ticker
   
      handleTickerChange({ticker})
      const info  = {"react_component":"TickerVsBenchmark"}
          
      return info;
    }

    async function GetTickerInfo({ tickerWant }) {
        const { tickerInfo } = stateTicker;
        
        // Filter to get the ticker details
        const tickerDetails = tickerInfo.filter(obj => obj[dfin.ticker] === tickerWant)[0];
        const tickerCode = tickerDetails?.[dfin.tickerCode];
        
        // Fetch the price info for the ticker
        const { price } = await getPriceInfo(tickerCode || tickerWant); // Use tickerCode or tickerWant as needed
        
        const navigateLink = `https://takestock.ai/Analytics/Ticker`;
        const info  = {...tickerDetails,...price
          ,learnMore:navigateLink,
          "EXTRA_INFORMATION_TO_ASSISTANT":"In presenting images use html and have width and height for logos of 50px. Have name and ticker also in html and have logo before name and ticker."}
        return info;
      }

      function GetTickerTechnicals({ component, ticker }) {
        handleTickerChange({ ticker, modal: false, goToPage: false });
      
        // Define the infoOptions with correct key-value pairs
        const infoOptions = {
          "Price vs Peers": "PriceVsPeers",
          "Performance": "Performance",
          "Volatility": "Volatility",
          "Correlation": "Correlation",
          "Technical Indicators": "TechnicalIndicators",
        };
      
        const filteredComponents = Object.keys(infoOptions)
          .filter(key => component.includes(key))
          .map(key => infoOptions[key]);
      
        return {
          react_component: filteredComponents
        };
      }
      

      function GetTrendingTicker({ tickerType, stockFilter }) {
        const stocks = stateScreener.stocks.filter(obj => obj[dfin.country] === "USA");
      
        let filteredStocks = [];
        let cols = [dfin.coLogo,
          dfin.ticker,
          dfin.coName,"Close"]
        // Apply filtering logic based on the stockFilter
        switch (stockFilter) {
          case "Market Cap":
            filteredStocks = stocks
              .sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap])
              .slice(0, 10);
            cols = [...cols,dfin.marketCap,] 
            break;
            
          case "Undervalued":
            filteredStocks = stocks
              .filter(obj => obj[dfin.fiancialRating] > 7) // Assuming higher valueRating means undervalued
              .slice(0, 10);
              cols = [...cols,dfin.pe,dfin.fiancialRating,dfin.evEbitda] 
            break;
      
          case "Strong Performance":
            filteredStocks = stocks
              .sort((a, b) => b[dfin.performance3M] - a[dfin.performance3M]) // Sort by 1-year performance
              .slice(0, 10);
              cols = [...cols,dfin.performance1D,
                dfin.performance1M,dfin.performance3M] 
            break;
      
          case "Strong Growth":
            filteredStocks = stocks
              .filter(obj => obj[dfin.growthRating] > 7) // Assuming growthRating > 7 means strong growth
              .slice(0, 10);
              cols = [...cols,...metricsGrowth] 
            break;
      
          default:
            filteredStocks = stocks.slice(0, 10); // Default case if no filter is applied
            break;
        }
      
        // Select only specific fields for the filtered stocks
        const stocksFilter = getSpecificArrayObjKeys(filteredStocks, cols);
      
        // Get the top 10 cryptos and ETFs
        const crypto = stateScreener.crypto.sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap]).slice(0, 10);
        const etf = stateScreener.etf.sort((a, b) => b[dfin.performance1Y] - a[dfin.performance1Y]).slice(0, 10);
      
        // Create the trend ticker object
        const trendTicker = {
          Stocks: stocksFilter,
          Crypto: crypto,
          ETF: etf,
        };
      
        // Return the relevant filtered data based on tickerType
        const info = trendTicker?.[tickerType] || stocksFilter;
        
        return {info,"learn_more":["/Analytics/Ticker","/Screener"]};
      }
      
      async function GetAllTicker() {
        const { tickerInfo } = stateTicker;
    
        return tickerInfo.slice(0,1000) 
      }

      async function GetTickerProfile({ticker}) {
        
        handleTickerChange({ticker,goToPage:false,modal:false})

        const { tickerInfo } = stateTicker;
        
        // Filter to get the ticker details
        const tickerDetails = tickerInfo.filter(obj => obj[dfin.ticker] === ticker)[0];
        const tickerCode = tickerDetails?.[dfin.tickerCode];
        
        // Fetch the price info for the ticker
        const { price } = await getPriceInfo(tickerCode || ticker); // Use tickerCode or tickerWant as needed
        
        const navigateLink = `/Analytics/Ticker`;
        const info  = {...tickerDetails,...price
          ,learnMore:navigateLink,"react_component":"TickerProfile"}

        return info 
      }

  return {GetTickerTechnicals,GetTrendingTicker,GetTickerInfo,GetAllTicker,
    GetTickerProfile,GetTickerVsPeers,GetTickerVsEconomy,GetTickerVsBenchmark,GetTickerBuySel}
}
