import React, { useEffect, useRef, useState } from 'react'
import useAssistantHandleRequest from './useAssistantHandleRequest';
import { useAppInfo } from 'AppState';


export default function useFetchResponse() {
    const { dispatchAssistant, stateAssistant } = useAppInfo();
    const {threadId, openai} = stateAssistant;
    const { handleRequiresAction } = useAssistantHandleRequest();

    async function fetchResponse({message,assistantId,assistantName,chats}) {
        dispatchAssistant({ loading: true, chatStatus: "running",loadingMessage:`Connecting to ${assistantName}`});

        try {
            let thread = threadId;
            // Create a new thread if not present
            if (!thread) {
                const threadNew = await openai.beta.threads.create();
                thread = threadNew.id;
                dispatchAssistant({ threadId: thread });
            }

            // Send the message to the assistant
            await openai.beta.threads.messages.create(thread, {
                role: 'user',
                content: message,
            });

            // Start streaming the assistant's response
            const stream = await openai.beta.threads.runs.create(thread, {
                assistant_id: assistantId,
                stream: true,
            });

            let assistantMessage = "";
            let chatsRef = [...chats]
            for await (const event of stream) {           
                // dispatchAssistant({ loading: false})
                
                if (event.event === 'thread.run.created') {
                //   console.log("RunID fetch",event.data.id)
                  dispatchAssistant({runId:event.data.id})
                }
                if (event?.data?.delta?.content) {
                    const content = event.data?.delta?.content[0]?.text?.value || "";
                    assistantMessage += content;
                    const updatedChats = [...chatsRef];
                    updatedChats[updatedChats.length - 1] = {
                        role: "assistant",
                        content: assistantMessage,
                    };
                    chatsRef = updatedChats;
                    dispatchAssistant({ chats: [...updatedChats] });
                }

                if (event?.data?.status === "requires_action") {
                    dispatchAssistant({"loadingMessage":`Analyzing...`});
  
                    // Handle required actions
                    await handleRequiresAction(event.data, event.data.id, thread, (updatedMessage) => {
                        dispatchAssistant({loading:false,loadingMessage:""});
                        const updatedChats = [...chatsRef];
                        updatedChats[updatedChats.length - 1] = {
                            role: "assistant",
                            content: updatedMessage,
                        };
                        chatsRef = updatedChats;
                        dispatchAssistant({ chats: updatedChats });
                    });
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            const updatedChats = [...chats];
            updatedChats[updatedChats.length - 1] = {
                role: "assistant",
                content: 'Sorry, something went wrong.',
            };
            dispatchAssistant({ chats: updatedChats,loading:false});
        } finally {
            dispatchAssistant({ loading: false,chatStatus:"complete","loadingMessage":null,runId:null});
        }
    }

    return { fetchResponse };
}
